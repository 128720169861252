<template>
  <div>
    <loader :isLoading="data_loaded"></loader>
  </div>
  <div>
    <div
      class="card shadow-lg bg-gradient-secondary w-100 border-0 mb-0 mb-4 pt-1 p-4 mt-4"
    >
      <div class="row">
        <div class="col-md-3">
          <router-link
            class="btn btn-default btn-md mt-2"
            :to="{ name: 'AddCarouselContent' }"
            >New</router-link
          >
        </div>
      </div>
      <div class="mt-1">
        <data-table
          v-if="data_loaded"
          id="adv"
          :options="dtb_options.extra_options"
          :actions="dtb_options.actions"
          :data="dtb_options.data"
          :columns="dtb_options.columns"
        />
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import DataTable from "@/components/Datatable";
import { alert } from "../../utils/alertUtils";
import Loader from "../../components/Loader";
export default {
  name: "Adverts",
  components: {
    "data-table": DataTable,
    loader: Loader,
  },
  data() {
    return {
      data_loaded: false,
      dtb_options: {
        data: [],
        actions: [
          {
            btnText: "Detail",
            btnClass: "btn btn-default btn-sm",
            iconClass: "fa fa-info-circle",
            btnFn: (uuid) => {
                this.$router.push({
                  name: "CarouselDetail",
                  params: { uuid: uuid },
                });
            },
            btnParamName: "uuid",
          },
        ],
        columns: [
          { title: "Title", data: "title" },

          { title: "Visibility", data: "visibility_status",
          render: (data) => data['visibility_status'] ? `YES` : 'NO'
          },
        ],
        extra_options: {},
      },
    };
  },
  methods: {
    fetch_adverts: function () {
      var url = `${process.env.VUE_APP_BACKEND_URL}/api/posts`;
      axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + this.$store.getters.getToken,
          },
        })
        .then((res) => {
            console.log(res.data)
          this.dtb_options.data = res.data;
          this.data_loaded = true;
        })
        .catch(() => {
          alert.error(
            "An error occured please try again,if the error persist kindly contact the owner of this site!"
          );
        });
    },
  },
  created() {
    this.fetch_adverts();
  },
};
</script>